import React from "react";

import Rabbits from "./images/rabbits.png";

const Footer = () => {
  return (
    <footer className="footer">
      Want to talk? Send me an email to felixinglavf@gmail.com
      <div style={{ display: "flex", flex: "1 0 0" }}></div>
      <img src={Rabbits} style={{ height: "20px" }} alt="Rabbit" />
    </footer>
  );
};

export default Footer;
