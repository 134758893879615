// About.js
import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./App.css";

import FelixIngla from "./images/felix-ingla-picture.jpeg";

function About() {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Felix Ingla</title>
        <meta
          name="description"
          content="Felix Ingla: Artist and internet entrepreneur. Founder of Pedra, offering virtual home staging and real estate photography enhancement. View my art portfolio and connect for collaborations."
        />
      </Helmet>
      <article>
        <p>I am an artist and internet entrepreneur.</p>
        <p>
          As an artist, I create physical works. You can see and buy my work{" "}
          <Link to="/shop" rel="noopener" title="Felix Ingla's Art Portfolio">
            here
          </Link>
          .
        </p>
        <p>
          As an internet entrepreneur, I am the founder of{" "}
          <a
            href="https://pedra.so"
            rel="noopener"
            alt="Felix Ingla, founder of Pedra"
          >
            {" Pedra"}
          </a>
          , a software web application to help real estate professionals create{" "}
          <a
            href="https://pedra.so/home-staging-virtual"
            rel="noopener"
            title="Virtual Home Staging with Pedra"
          >
            virtual home stagings
          </a>{" "}
          and improve their{" "}
          <a
            href="https://pedra.so/real-estate-photography"
            rel="noopener"
            title="Real Estate Photography Enhancement with Pedra"
          >
            real estate photography
          </a>
          .
        </p>
        <p>
          If you want to say hi or collaborate, send an email to{" "}
          <a href="mailto:felixinglavf@gmail.com">felixinglavf@gmail.com</a>.
        </p>
        <p style={{ marginTop: "14px" }}>
          <img
            src={FelixIngla}
            alt="Felix Ingla"
            height={"120px"}
            style={{ width: "120px", height: "120px" }}
          />
        </p>
      </article>
    </>
  );
}

export default About;
